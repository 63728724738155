import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setReward} from "../../slices/app/reducer";
import IconTon from "../icons/IconTon";
import IconLottyStar from "../icons/IconLottyStar";
import {formatNumber} from "../../helpers/format_helper";
import IconLottyCoin from "../icons/IconLottyCoin";
import IconLottyTicket from "../icons/IconLottyTicket";

const RewardNumber = () => {
    const dispatch = useDispatch();
    const {reward} = useSelector(state => state.App); // Массив объектов наград
    const [currentRewardIndex, setCurrentRewardIndex] = useState(0); // Индекс текущей награды
    const [showReward, setShowReward] = useState(false);
    const [rewardIcon, setRewardIcon] = useState(null);
    const [rewardType, setRewardType] = useState(null);

    useEffect(() => {
        if (reward && reward.length > 0) {
            // Когда приходят новые награды, сбрасываем индекс и начинаем показ первой
            setCurrentRewardIndex(0);
            setShowReward(true);
        }
    }, [reward]);

    useEffect(() => {
        if (showReward && reward && reward.length > 0 && currentRewardIndex < reward.length) {
            const currentReward = reward[currentRewardIndex]; // Получаем текущую награду

            // Устанавливаем иконку награды
            let reward_icon;
            switch (currentReward.reward_type) {
                case "ton":
                    reward_icon = <IconTon style={{
                        height: 20,
                        width: 15
                    }} />;
                    break;
                case "coin":
                    reward_icon = <IconLottyCoin
                        style={{
                            height: 20,
                            width: 15
                        }}
                    />;
                    break;
                case "star":
                    reward_icon = <IconLottyStar
                        style={{
                            height: 20,
                            width: 15
                        }}
                    />;
                    break;
                case "ticket":
                    reward_icon = <IconLottyTicket
                        style={{
                            height: 20,
                            width: 15
                        }}
                    />;
                    break;
                default:
                    reward_icon = '';
            }
            setRewardIcon(reward_icon);

            // Устанавливаем тип транзакции (debit/credit)
            let transaction_type;
            switch (currentReward.transaction_type) {
                case "credit":
                    transaction_type = <span style={{fontSize: 24}} className={"text-success"}>+ {formatNumber(currentReward.amount)}</span>;
                    break;
                case "debit":
                    transaction_type = <span style={{fontSize: 24}} className={"text-danger"}>- {formatNumber(currentReward.amount)}</span>;
                    break;
                default:
                    transaction_type = '';
            }
            setRewardType(transaction_type);

            // Убираем награду через 2 секунды, показываем следующую
            const timeout = setTimeout(() => {
                setShowReward(false);

                // Через 500 мс показываем следующую награду, если она есть
                const nextTimeout = setTimeout(() => {
                    if (currentRewardIndex + 1 < reward.length) {
                        setCurrentRewardIndex(currentRewardIndex + 1);
                        setShowReward(true);
                    } else {
                        // Все награды показаны, очищаем массив наград
                        dispatch(setReward([]));
                    }
                }, 50); // Задержка перед показом следующей награды

                return () => clearTimeout(nextTimeout);
            }, 1500);

            return () => clearTimeout(timeout);
        }
    }, [showReward, currentRewardIndex, reward, dispatch]);

    return (
        <>
            {showReward && (
                <div className={`position-absolute d-flex gap-1 align-items-center reward-animation ${showReward ? 'fly' : ''}`}>
                    {rewardType}
                    {rewardIcon}
                </div>
            )}
        </>
    );
}

export default RewardNumber;