import Stories from 'stories-react';
import 'stories-react/dist/index.css';
import { useEffect, useState } from "react";
import FirstStory from "./FirstStory";
import SecondStory from "./SecondStory";
import ThirdStory from "./ThirdStory";
import { useDispatch } from "react-redux";
import {setWelcomeModal } from "../../../slices/app/reducer";
import StoryHeader from "./StoryHeader";
import {motion, useAnimation} from "framer-motion";
import {updateUserApi} from "../../../helpers/real_api_helper";

const WelcomeStories = () => {
    const [currentStory, setCurrentStory] = useState(0);
    const [planetDeg, setPlanetDeg] = useState(0)
    const [itemsVw, setItemsVw] = useState(0)
    const dispatch = useDispatch()
    const controls = useAnimation(); // Для первого айтема
    const secondItemControls = useAnimation(); // Для второго айтема
    const thirdItemControls = useAnimation(); // Для третьего айтема
    const [isVisibleFirstItem, setIsVisibleFirstItem] = useState(true); // Управляет отображением компонента

    const stories = [
        {
            type: 'component',
            component: FirstStory,
            duration: 5000,
            header: StoryHeader
        },
        {
            type: 'component',
            component: SecondStory,
            duration: 5000,
            header: StoryHeader
        },
        {
            type: 'component',
            component: ThirdStory,
            duration: 5000,
            header: StoryHeader
        },
    ];

    const onAllStoriesEnd = () => {
        let data = {
            show_welcome_stories: false
        }
        updateUserApi(data)
        dispatch(setWelcomeModal(false))
    };

    useEffect(() => {
        if (currentStory === 0) {
                controls.start({
                    x: 0,
                    transition: {
                        type: "spring",
                        stiffness: 100,
                        damping: 25,
                        delay: 0.5
                    },
                }).then(() => {
                    if (controls)
                        controls.start({
                            x: 0, // Возвращаем элемент на экран
                            opacity: 1, // Обеспечиваем видимость
                            transition: {
                                type: "spring",
                                stiffness: 100,
                                damping: 25
                            }
                        });
                }).catch((err) => {

                });
                setPlanetDeg(0);
                setItemsVw(0)
                setIsVisibleFirstItem(true)
        } else if (currentStory === 1) {
            thirdItemControls.start({
                y: "0",
                transition: {
                    type: "spring",
                    stiffness: 100,
                    damping: 30,
                    delay: 1
                },
            })
            controls.start({
                x: "250%",
                transition: {
                    type: "spring",
                    stiffness: 100,
                    damping: 25,
                },
            }).then(() => {
                setItemsVw(-100)
                setIsVisibleFirstItem(false)
            })

            setPlanetDeg(-60);

        } else if (currentStory === 2) {
            setPlanetDeg(-120);
            setItemsVw(-200)
            thirdItemControls.start({
                y: "-100%",
                transition: {
                    type: "spring",
                    stiffness: 100,
                    damping: 30,
                    delay: 1
                },
            })
        }
    }, [currentStory, controls, secondItemControls, thirdItemControls]);

    const onStoryChange = (number) => {
        setCurrentStory(number);
    };

    const animationVariants = {
        initial: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1 } },
        exit: { x: "-100%", opacity: 0, transition: { duration: 1 } }
    };

    return (
        
            <div className={"story-bg"}>
                    <motion.div
                        key="story"
                        initial="initial"
                        animate="visible"
                        exit="exit"
                        variants={animationVariants}
                        className={"stories"}
                    >
                        <Stories
                            onStoryChange={onStoryChange}
                            onAllStoriesEnd={onAllStoriesEnd}
                            stories={stories}
                            currentStory={currentStory}
                            currentIndex={currentStory}
                        />
                        <div className={"planet-bg-con"}>
                            <div className={"planet-img-con"} style={{
                                transform: `rotate(${planetDeg}deg)`
                            }}>
                                <img alt="" src={"/images/stories/moon.svg"} loading={"lazy"}/>
                            </div>
                            <div className={"items-con"} style={{
                                transform: `translateX(${itemsVw}vw)`
                            }}>
                                {/* Первый айтем */}
                                <div className={"item-con parking-item first-item"}>
                                    {isVisibleFirstItem &&
                                        <motion.div
                                            initial={{
                                                x: '-100%'
                                            }}
                                            animate={controls}       // Управление анимацией через controls
                                        >
                                            <div className="img-con">
                                                <img src="/images/stories/flyer.svg" alt="Flyer" />
                                            </div>
                                        </motion.div>
                                    }

                                </div>

                                {/* Второй айтем */}
                                <div className={"item-con second-item"}>
                                    <motion.div
                                        animate={secondItemControls} // Управление анимацией через secondItemControls
                                    >
                                        <div className="img-con">
                                            <img src="/images/stories/collect.svg" alt="Collect" />
                                        </div>
                                    </motion.div>

                                </div>

                                {/* Третий айтем */}
                                <div className={"item-con parking-item third-item"}>
                                    <motion.div
                                        initial={{
                                            y: 0
                                        }}
                                        animate={thirdItemControls}    // Управление анимацией через thirdItemControls
                                    >
                                        <div className="img-con">
                                            <img src="/images/stories/cashout.svg" alt="Cashout" />
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </motion.div>

            </div>

    );
};

export default WelcomeStories;