import {Caption, Card} from "@telegram-apps/telegram-ui";
import React from "react";

const TunedCard = ({children}) => {

    return (
        <Card className={"p-3 d-flex flex-column align-items-center w-100 gap-1 lotty-item"}>
            {children}
            <div className={"custom-hr"} ></div>
            <Caption className={"subtitle-color"}>Your finished place</Caption>
        </Card>
    )
}

export default TunedCard