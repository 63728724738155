import IconTon from "../Components/icons/IconTon";
import IconLottyStar from "../Components/icons/IconLottyStar";
import {formatNumber} from "./format_helper";
import React from "react";
import first from "../assets/lottie/dotlottie/1st.lottie";
import second from "../assets/lottie/dotlottie/2nd.lottie";
import third from "../assets/lottie/dotlottie/3rd.lottie";
import dollar from "../assets/lottie/dotlottie/ton-coin.lottie";
import IconLottyCoin from "../Components/icons/IconLottyCoin";
import star from "../assets/lottie/dotlottie/star.lottie";
import crown from "../assets/lottie/dotlottie/crown.lottie";
import icon_new from "../assets/lottie/dotlottie/new.lottie";
import icon_soon from "../assets/lottie/dotlottie/soon.lottie";
import CountUp from "react-countup";
import OptimizedLottie from "../Components/custom/OptimizedLottie";
import LazyDotLottie from "../Components/custom/LazyDotLottie";
import IconLottyTicket from "../Components/icons/IconLottyTicket";

export const getPrize = (type, amount, with_plus = false, useCountUp = false, content_end = false, card_variant = false) => {
    let icon
    let color
    switch(type){
        case 'ton':
            color='text-blue'
            icon = <IconTon
                style={{
                    height: card_variant ? 12 : 16,
                    weight: card_variant ? 12 : 16,
                }}
            />
            break
        case 'star':
            color=''
            icon = <IconLottyStar style={{
                height: card_variant ? 12 : 16,
            }} />
            break
        case 'coin':
            color='text-green'
            icon = <IconLottyCoin style={{
                height: card_variant ? 12 : 16,
            }} />
            break
        case 'ticket':
            icon = <IconLottyTicket style={{
                height: card_variant ? 11 : 11,
            }} />
            break
        default:
            return null
    }

    const checkHasDecimals = (amount) => {
        return !Number.isInteger(amount);
    }

    return (
        <div className={`d-flex align-items-center gap-1 ${content_end ? 'justify-content-end' : ''}`}>
             <span className={color}>
                 {with_plus && '+'}
                 {useCountUp ? <CountUp
                     start={0}
                     decimals={checkHasDecimals(amount) ? 2 : ''}
                     decimal={'.'}
                     end={amount}
                 /> : formatNumber(amount)}
            </span>
            {icon}
        </div>
    )
}

const isPlaceInRange = (place, range) => {
    const [start, end] = range.split('-').map(Number);
    return place >= start && place <= end;
};

export const getMedal = (place, prizes, el) => {
    for (let i = 0; i < prizes.length; i++) {
        if (isPlaceInRange(place, prizes[i].range)) {
            if (i === 0) {
                return (
                    <OptimizedLottie
                        src={first}
                        loop={false}
                        autoplay
                        style={{
                            height: 24,
                            width: 33
                        }}
                    />
                );
            } else if (i === 1) {
                return (
                    <OptimizedLottie
                        src={second}
                        loop={false}
                        autoplay
                        style={{
                            height: 24,
                            width: 33
                        }}
                    />
                );
            } else if (i === 2) {
                return (
                    <OptimizedLottie
                        src={third}
                        loop={false}
                        autoplay
                        style={{
                            height: 24,
                            width: 33
                        }}
                    />
                );
            }
            else {
                return getLottieIcon(el.prize.type)
            }
        }
    }
    return null;
};

export const getLottieIcon = (type) => {
    switch (type) {
        case 'ton':
            return (
                <LazyDotLottie
                    src={dollar}
                    loop={false}
                    autoplay
                    style={{
                        height: 24,
                        width: 33,
                    }}
                />
            );
        case 'star':
            return (
                <LazyDotLottie
                    src={star}
                    loop={false}
                    autoplay
                    style={{
                        height: 24,
                        width: 33,
                    }}
                />
            );
        case 'coin':
            return (
                <LazyDotLottie
                    src={crown}
                    loop={false}
                    autoplay
                    style={{
                        height: 24,
                        width: 33,
                    }}
                />
            );
        default:
            return null;
    }
};

export const checkIsNew = (current) => {
    let percentParticipants = current.current_participants * 100 / current.max_participants
    if (percentParticipants <= 50)
        return (
            <OptimizedLottie
                src={icon_new}
                loop
                autoplay
                style={{
                    height: 24,
                    width: 33
                }}
            />
        )
    else if (percentParticipants > 70)
        return (
            <OptimizedLottie
                src={icon_soon}
                loop
                autoplay
                style={{
                    height: 24,
                    width: 33
                }}
            />
        )
}

export default function ordinal(number) {
    let suffix = 'th';

    // Проверка исключений
    if (number % 100 < 11 || number % 100 > 13) {
        switch (number % 10) {
            case 1:
                suffix = 'st';
                break;
            case 2:
                suffix = 'nd';
                break;
            case 3:
                suffix = 'rd';
                break;
            default:
                suffix = ''
        }
    }

    return number + suffix;
}