export const lotty_styles = [
    { picture: "lotty1.png", backgroundColor: "#2D4E5E", style_number: 1 },
    { picture: "lotty2.png", backgroundColor: "#67733c", style_number: 2 },
    { picture: "lotty3.png", backgroundColor: "#54849F", style_number: 3 },
    { picture: "lotty4.png", backgroundColor: "#0D354A", style_number: 4 },
    { picture: "lotty5.png", backgroundColor: "#663C3A", style_number: 5 },
    { picture: "lotty6.png", backgroundColor: "#891616", style_number: 6 },
    { picture: "lotty7.png", backgroundColor: "#663754", style_number: 7 },
    { picture: "lotty8.png", backgroundColor: "#7E2922", style_number: 8 },
    { picture: "lotty9.png", backgroundColor: "#544A6B", style_number: 9 },
    { picture: "lotty10.png", backgroundColor: "#5C4383", style_number: 10 },
    { picture: "lotty11.png", backgroundColor: "#634d45", style_number: 11 },
    { picture: "lotty12.png", backgroundColor: "#39137B", style_number: 12 },
    { picture: "lotty13.png", backgroundColor: "#350091", style_number: 13 },
    { picture: "lotty14.png", backgroundColor: "#736145", style_number: 14 },
    { picture: "lotty15.png", backgroundColor: "#795014", style_number: 15 },
    { picture: "lotty16.png", backgroundColor: "#603B03", style_number: 16 },
    { picture: "lotty17.png", backgroundColor: "#A16710", style_number: 17 },
    { picture: "lotty18.png", backgroundColor: "#9C518E", style_number: 18 },
    { picture: "lotty19.png", backgroundColor: "#9C3B8A", style_number: 19 },
    { picture: "lotty20.png", backgroundColor: "#882647", style_number: 20 },
    { picture: "lotty21.png", backgroundColor: "#910076", style_number: 21 },
    { picture: "lotty22.png", backgroundColor: "#383838", style_number: 22 },
    { picture: "lotty23.png", backgroundColor: "#3D5B3D", style_number: 23 },
    { picture: "lotty24.png", backgroundColor: "#7e5c29", style_number: 24 },
    { picture: "lotty25.png", backgroundColor: "#125015", style_number: 25 },
    { picture: "lotty26.png", backgroundColor: "#054D08", style_number: 26 },
    { picture: "lotty27.png", backgroundColor: "#2A2929", style_number: 27 },
    { picture: "lotty28.png", backgroundColor: "#4A4A4A", style_number: 28 },
    { picture: "lotty29.png", backgroundColor: "#644d2a", style_number: 29 },
    { picture: "lotty30.png", backgroundColor: "#2d2d2d", style_number: 30 },
    { picture: "lotty31.png", backgroundColor: "#363636", style_number: 31 },
    { picture: "lotty32.png", backgroundColor: "#6E6467", style_number: 32 },
    { picture: "lotty33.png", backgroundColor: "#803850", style_number: 33 },
    { picture: "lotty34.png", backgroundColor: "#956f37", style_number: 34 },
    { picture: "lotty35.png", backgroundColor: "#505347", style_number: 35 },
]