import {FaHourglass} from "react-icons/fa";
import React from "react";

const ParticipantsReminder = () => {
    return (
        <div className={"d-flex justify-content-center px-3"}>
            <div className="alert text-green p-2 mb-0 justify-content-center border-dashed d-flex align-items-center gap-2" role="alert">
                <FaHourglass className={"text-blue"} />
                <div className={"text-center text-blue"}>
                    The Lotty starts when all participants join
                </div>
            </div>
        </div>
    )
}

export default ParticipantsReminder