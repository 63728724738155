import {Caption, Card, LargeTitle} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import {lotty_styles} from "../../../../helpers/lotty_styles";

const TicketNumber = ({selectedRaffle}) => {
    const [style, setStyle] = useState(false);

    useEffect(() => {
        if (selectedRaffle) {
            const key = selectedRaffle?.style_number ?? 1;
            const style = lotty_styles.find(el => el.style_number === key)
            setStyle(style)
        }
    }, [selectedRaffle])

    return (
        <Card className={"p-3 d-flex flex-column align-items-center w-100 gap-1 lotty-item"} style={{
            background: style.backgroundColor
        }}>
            <LargeTitle className={"text-white"} weight={1}>{selectedRaffle?.ticket_number}</LargeTitle>
            <div className={"custom-hr"} ></div>
            <Caption className={"subtitle-color"}>Your ticket number</Caption>
        </Card>
    )
}

export default TicketNumber