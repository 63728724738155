import {lazy, Suspense} from "react";
import {Navigate} from "react-router-dom";
import {Spinner} from "@telegram-apps/telegram-ui";

const Home = lazy(() => import("../pages/Home"));
const Profile = lazy(() => import("../pages/Profile"));
const Rewards = lazy(() => import("../pages/Rewards"));
const Friends = lazy(() => import("../pages/Friends"));
const Converter = lazy(() => import("../pages/Converter"));
const Withdraw = lazy(() => import("../pages/Withdraw"));
const Top100Winners = lazy(() => import("../pages/Top100Winners"));
const Top100Users = lazy(() => import("../pages/Top100Users"));
const Roulette = lazy(() => import("../pages/Roulette"));

export const SpinnerContent = () => {
    return (
        <div className={"min-vh-100 d-flex align-items-center justify-content-center"}>
            <Spinner size={'l'}/>
        </div>
    )
}
export const authProtectedRoutes = [
    {path: '/lotty', component: <Suspense fallback={<SpinnerContent/>}><Home/></Suspense>},
    {path: '/wallet', component: <Suspense fallback={<SpinnerContent/>}><Profile/></Suspense>},
    {path: '/rewards', component: <Suspense fallback={<SpinnerContent/>}><Rewards/></Suspense>},
    {path: '/friends', component: <Suspense fallback={<SpinnerContent/>}><Friends/></Suspense>},
    {path: '/converter', component: <Suspense fallback={<SpinnerContent/>}><Converter/></Suspense>},
    {path: '/withdraw', component: <Suspense fallback={<SpinnerContent/>}><Withdraw/></Suspense>},
    {path: '/top100-winners', component: <Suspense fallback={<SpinnerContent/>}><Top100Winners/></Suspense>},
    {path: '/top100-players', component: <Suspense fallback={<SpinnerContent/>}><Top100Users/></Suspense>},
    {path: '/roulette', component: <Suspense fallback={<SpinnerContent/>}><Roulette/></Suspense>},
    {
        path: "/",
        exact: true,
        component: <Navigate to="/lotty" />,
    },
    { path: "*", component: <Navigate to="/lotty" /> },
];