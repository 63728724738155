import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    topRaffles: [],
    loading: false,
    loaded: false,
}

const appSlice = createSlice({
    name: "top_raffles",
    initialState,
    reducers: {
        setTopRaffles(state, action) {
            state.topRaffles = action.payload
            state.topRaffleSkeleton = true
        },
        setLoading(state, action) {
            state.loading = action.payload
        }
    }
})

export const {
    setTopRaffles,
    setLoading
} = appSlice.actions

export default appSlice.reducer