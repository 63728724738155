import Footer from "./Footer";
import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import RewardNumber from "../Components/custom/RewardNumber";
import { getCounters } from "../slices/app/thunk";
import ModalFinal from "../Components/custom/ModalFinal";
import ModalPersonalWinner from "../Components/custom/Raffle/Modals/ModalPersonalWinner/ModalPersonalWinner";
import {getNotifications} from "../slices/users/thunk";
import RaffleModals from "../Components/custom/Raffle/Modals/RaffleModals";
import WelcomeStories from "../Components/custom/WelcomeStories/WelcomeStories";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import LoadingWrapper from "../Components/custom/LoadingWrapper/LoadingWrapper";
import AlertModal from "../Components/custom/AlertModal";

const Layout = (props) => {
    const { showConfetti,  showWelcomeModal, majority } = useSelector(state => state.App);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCounters());
        dispatch(getNotifications());
    }, [dispatch]);



    return (
        <LoadingWrapper>
            {majority == null ? <AlertModal /> :
                showWelcomeModal ?
                    <WelcomeStories />
                    :
                    <React.Fragment>

                        {showConfetti && <div className="confetti">
                            <Fireworks autorun={{ speed: 1, duration: 0.5 }} />
                        </div>}

                        <RaffleModals />
                        <ModalPersonalWinner />
                        <ToastContainer
                            position="top-center"
                            theme="dark"
                            autoClose={2000}
                            className="p-3"
                            hideProgressBar
                        />
                        <ModalFinal />
                        <RewardNumber />
                        <div className="content-wrapper tg-secondary-bg">
                            {props.children}
                        </div>
                        <Footer />

                    </React.Fragment>
            }

        </LoadingWrapper>

    );
};

export default Layout;