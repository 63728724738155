const IconLottyCoin = (props) => {
    return (
        // <svg {...props} viewBox="0 0 512 512">
        //     <g>
        //         <circle fill="#67a741" cx="256" cy="256" r="249.6"/>
        //         <circle fill="#335220" cx="256" cy="256" r="206"/>
        //     </g>
        //     <path fill="#67a741" d="M317,387.8c-11.7-.8-16.5-5.1-27.2-9.3-9.1-3.7-18.1-6.6-25.2-7.8-28.2-4.3-45.8,8.6-71,16.5-23.6,6.9-38.6-24.9-37.1-44,7.4-58.8,119.7-67.3,97.1-89.2-8-2.6-23.9,5.2-31.8,7-18.1,5.7-37.6,11.7-56.3,17.1-43,16.6-43.7-14.8-57.4-46.8-6.5-17.1-6.7-17.5-6.3-20.3.8-7.3,9.1-13.4,16-10.8,11.2,6.3,54.8,43.5,58.2,45.6,7.5,6.7,16.7,6.1,21.4-4.7,1.1-2.2,19.4-50.5,38.7-101.8,5.4-12.7,5.8-19.8,11.7-26.4,6-5.1,14.6-3.6,18.8,3.2,12.8,32.7,31,82.4,39.9,107,5.7,16,9.9,35.1,27.6,23.1-.7.6,61.3-48.3,58.2-45.2,8.2-4.8,19.3,4,17.8,12.9-8.6,27.3-49.3,138.4-56.7,151.1-7.1,15.9-19.5,23.9-36.9,23h.5Z"/>
        // </svg>

        // <svg {...props} version="1.1" viewBox="0 0 512 512">
        //     <g>
        //         <circle fill="#67a741" cx="256" cy="256" r="249.6"/>
        //         <circle fill="#335220" cx="256" cy="256" r="228.6"/>
        //     </g>
        //     <path fill="#87ce59" d="M309.3,368.9c-10.2-.7-14.4-4.4-23.7-8.1-7.9-3.2-15.8-5.8-22-6.8-24.6-3.8-39.9,7.5-61.9,14.4-20.6,6-33.7-21.7-32.4-38.4,6.5-51.3,104.4-58.7,84.7-77.8-7-2.3-20.8,4.5-27.7,6.1-15.8,5-32.8,10.2-49.1,14.9-37.5,14.5-38.1-12.9-50.1-40.8-5.7-14.9-5.8-15.3-5.5-17.7.7-6.4,7.9-11.7,14-9.4,9.8,5.5,47.8,37.9,50.8,39.8,6.5,5.8,14.6,5.3,18.7-4.1,1-1.9,16.9-44,33.8-88.8,4.7-11.1,5.1-17.3,10.2-23,5.2-4.4,12.7-3.1,16.4,2.8,11.2,28.5,27,71.9,34.8,93.3,5,14,8.6,30.6,24.1,20.1-.6.5,53.5-42.1,50.8-39.4,7.2-4.2,16.8,3.5,15.5,11.3-7.5,23.8-43,120.7-49.4,131.8-6.2,13.9-17,20.8-32.2,20.1h.4v-.2Z"/>
        // </svg>


        // <svg {...props} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="url(#paint0_linear_13_35)"/>
        //     <path d="M324.176 395.598C311.185 394.79 305.836 390.521 293.991 386.252C283.93 382.559 273.868 379.559 265.972 378.405C234.641 374.021 215.154 387.059 187.135 395.021C160.898 401.944 144.214 369.982 145.87 350.713C154.148 291.519 278.835 282.981 253.745 260.942C244.83 258.288 227.254 266.134 218.466 267.981C198.343 273.75 176.691 279.75 155.931 285.173C108.171 301.904 107.407 270.288 92.1232 238.095C84.8636 220.903 84.7362 220.441 85.1183 217.672C86.0098 210.287 95.1799 204.172 102.949 206.826C115.43 213.172 163.828 250.557 167.649 252.75C175.927 259.442 186.243 258.865 191.465 248.019C192.739 245.826 212.989 197.249 234.513 145.556C240.499 132.748 241.009 125.594 247.504 119.017C254.127 113.94 263.679 115.44 268.392 122.248C282.656 155.133 302.779 205.21 312.713 229.903C319.081 246.057 323.666 265.211 343.407 253.096C342.643 253.673 411.546 204.518 408.107 207.633C417.277 202.787 429.504 211.672 427.848 220.672C418.296 248.134 373.083 359.944 364.932 372.751C357.035 388.79 343.28 396.752 323.921 395.944H324.431V395.713L324.176 395.598Z" fill="url(#paint1_linear_13_35)"/>
        //     <defs>
        //         <linearGradient id="paint0_linear_13_35" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="#01D012"/>
        //             <stop offset="1" stopColor="#006A09"/>
        //         </linearGradient>
        //         <linearGradient id="paint1_linear_13_35" x1="256.5" y1="116" x2="256.5" y2="396" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="white"/>
        //             <stop offset="1" stopColor="#D1D1D1"/>
        //         </linearGradient>
        //     </defs>
        // </svg>


        // <svg {...props} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="url(#paint0_linear_13_35)"/>
        //     <path d="M324.176 395.598C311.185 394.79 305.836 390.521 293.991 386.252C283.93 382.559 273.868 379.559 265.972 378.405C234.641 374.021 215.154 387.059 187.135 395.021C160.898 401.944 144.214 369.982 145.87 350.713C154.148 291.519 278.835 282.981 253.745 260.942C244.83 258.288 227.254 266.134 218.466 267.981C198.343 273.75 176.691 279.75 155.931 285.173C108.171 301.904 107.407 270.288 92.1232 238.095C84.8636 220.903 84.7362 220.441 85.1183 217.672C86.0098 210.287 95.1799 204.172 102.949 206.826C115.43 213.172 163.828 250.557 167.649 252.75C175.927 259.442 186.243 258.865 191.465 248.019C192.739 245.826 212.989 197.249 234.513 145.556C240.499 132.748 241.009 125.594 247.504 119.017C254.127 113.94 263.679 115.44 268.392 122.248C282.656 155.133 302.779 205.21 312.713 229.903C319.081 246.057 323.666 265.211 343.407 253.096C342.643 253.673 411.546 204.518 408.107 207.633C417.277 202.787 429.504 211.672 427.848 220.672C418.296 248.134 373.083 359.944 364.932 372.751C357.035 388.79 343.28 396.752 323.921 395.944H324.431V395.713L324.176 395.598Z" fill="url(#paint1_linear_13_35)"/>
        //     <defs>
        //         <linearGradient id="paint0_linear_13_35" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="#00B10F"/>
        //             <stop offset="1" stopColor="#006309"/>
        //         </linearGradient>
        //         <linearGradient id="paint1_linear_13_35" x1="256.5" y1="116" x2="256.5" y2="396" gradientUnits="userSpaceOnUse">
        //             <stop stopColor="white"/>
        //             <stop offset="1" stopColor="#D1D1D1"/>
        //         </linearGradient>
        //     </defs>
        // </svg>

        <svg {...props} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="url(#paint0_linear_13_35)"/>
            <path d="M324.176 395.598C311.185 394.79 305.836 390.521 293.991 386.252C283.93 382.559 273.868 379.559 265.972 378.405C234.641 374.021 215.154 387.059 187.135 395.021C160.898 401.944 144.214 369.982 145.87 350.713C154.148 291.519 278.835 282.981 253.745 260.942C244.83 258.288 227.254 266.134 218.466 267.981C198.343 273.75 176.691 279.75 155.931 285.173C108.171 301.904 107.407 270.288 92.1232 238.095C84.8636 220.903 84.7362 220.441 85.1183 217.672C86.0098 210.287 95.1799 204.172 102.949 206.826C115.43 213.172 163.828 250.557 167.649 252.75C175.927 259.442 186.243 258.865 191.465 248.019C192.739 245.826 212.989 197.249 234.513 145.556C240.499 132.748 241.009 125.594 247.504 119.017C254.127 113.94 263.679 115.44 268.392 122.248C282.656 155.133 302.779 205.21 312.713 229.903C319.081 246.057 323.666 265.211 343.407 253.096C342.643 253.673 411.546 204.518 408.107 207.633C417.277 202.787 429.504 211.672 427.848 220.672C418.296 248.134 373.083 359.944 364.932 372.751C357.035 388.79 343.28 396.752 323.921 395.944H324.431V395.713L324.176 395.598Z" fill="url(#paint1_linear_13_35)"/>
            <defs>
                <linearGradient id="paint0_linear_13_35" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#67B100"/>
                    <stop offset="1" stopColor="#376001"/>
                </linearGradient>
                <linearGradient id="paint1_linear_13_35" x1="256.5" y1="116" x2="256.5" y2="396" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#D1D1D1"/>
                </linearGradient>
            </defs>
        </svg>



    )
}

export default IconLottyCoin