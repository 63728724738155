import {Headline, LargeTitle} from "@telegram-apps/telegram-ui";
import {motion} from 'framer-motion'
const ThirdStory = () => {
    return (
        <div className={"story-container h-100"} style={{
        }}>
            <div className={"d-flex flex-column w-100 p-4 text-uppercase"}>
                
                    <motion.div
                        className={"pb-3"}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Headline className={"fw-200 text-grey"}>
                            Cash Out
                        </Headline>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                    >
                        <LargeTitle className={"text-white"} weight={2}>
                            <span className={"text-purple"}>Withdraw TON</span> to your wallet
                        </LargeTitle>
                    </motion.div>
                
            </div>
        </div>
    )
}
export default ThirdStory