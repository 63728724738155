import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "../../../Drawer";
import {dequeueWinnerData, setSelectedRaffle, setShowWinnerModal, setWinnerData} from "../../../../../slices/raffles/reducer";
import {setFinalModalData, setShowFinalWinning, setShowPrepareWinning} from "../../../../../slices/app/reducer";
import { updateNotificationApi } from "../../../../../helpers/real_api_helper";
import PrepareRaffle from "./PrepareRaffle";
import LottyWinner from "./LottyWinner";
import LottyDisqualified from "./LottyDisqualified";

// Загружаем компонент фейерверков только когда это нужно
const FireworksParticles = lazy(() => import("../../../FireworksParticles"));

const ModalPersonalWinner = () => {
    const dispatch = useDispatch();
    const { showWinnerModal, winnerData, winnerQueue } = useSelector((state) => state.Raffles);
    const { showPrepareWinning, showFinalWinning } = useSelector((state) => state.App);

    // Закрытие модального окна
    const modalClose = async () => {
        await dispatch(dequeueWinnerData());
        dispatch(setShowWinnerModal(false));
        dispatch(setFinalModalData(false));
        await updateNotificationApi(winnerData?.notification); // Отправляем данные на сервер
    };

    // Обновляем модальное окно при изменении очереди
    useEffect(() => {
        if (!showWinnerModal && winnerQueue.length > 0) {
            // Убираем старые данные победителя
            dispatch(setWinnerData(null));
            // Показываем следующий элемент из очереди
            const nextWinnerData = winnerQueue[0];
            dispatch(setWinnerData(nextWinnerData));
            dispatch(setShowWinnerModal(true));
        }
    }, [winnerQueue, showWinnerModal, dispatch]);

    // Управляем этапами модального окна
    useEffect(() => {
        if (showWinnerModal) {
            dispatch(setShowPrepareWinning(true));
            dispatch(setShowFinalWinning(false));
        }
    }, [showWinnerModal, dispatch]);

    return (
        showWinnerModal && <Drawer
            key={winnerData?.raffle || "drawer-default"} // Принудительная перерисовка Drawer
            customClass={"winner-drawer"}
            isOpen={showWinnerModal}
            onClose={modalClose}
        >
            <Suspense fallback={<div>Loading...</div>}>
                {showPrepareWinning && <PrepareRaffle />}
                {showFinalWinning &&
                    winnerData?.type === 'finished' ?
                    <LottyWinner modalClose={modalClose} FireworksParticlesComponent={FireworksParticles} />
                    :
                    <LottyDisqualified modalClose={modalClose} />
                }
            </Suspense>
        </Drawer>
    );
};

export default ModalPersonalWinner;