import React, { useState, useEffect } from 'react';
import LoadingContainer from "./LoadingContainer";
import { useSelector } from "react-redux";

// Глобальный флаг для отслеживания загрузки
let isAssetsPreloaded = false;

function LoadingWrapper({ children }) {
    const [loadingProgress, setLoadingProgress] = useState(0);
    const { user } = useSelector(state => state.Auth);
    const { showWelcomeModal } = useSelector(state => state.App);
    const [isLoading, setIsLoading] = useState(!isAssetsPreloaded);
    const [assets, setAssets] = useState([
        '/images/icons/1friend.png',
        '/images/icons/boost_channel.png',
        '/images/icons/facebook.png',
        '/images/icons/instagram.png',
        '/images/icons/lotty-coins.png',
        '/images/icons/participate.png',
        '/images/icons/roulette.png',
        '/images/icons/silver_star.png',
        '/images/icons/stories.png',
        '/images/icons/telegram.png',
        '/images/icons/telegram_star.png',
        '/images/icons/tiktok.png',
        '/images/icons/twitter.png',
        '/images/icons/youtube.png',
        '/audio/money.mp3',
        '/images/lotties/lotty1.png',
        '/images/lotties/lotty2.png',
        '/images/lotties/lotty3.png',
        '/images/lotties/lotty4.png',
        '/images/lotties/lotty5.png',
        '/images/lotties/lotty6.png',
        '/images/lotties/lotty7.png',
        '/images/lotties/lotty8.png',
        '/images/lotties/lotty9.png',
        '/images/lotties/lotty10.png',
        '/images/lotties/lotty11.png',
        '/images/lotties/lotty12.png',
        '/images/lotties/lotty13.png',
        '/images/lotties/lotty14.png',
        '/images/lotties/lotty15.png',
        '/images/lotties/lotty16.png',
        '/images/lotties/lotty17.png',
        '/images/lotties/lotty18.png',
        '/images/lotties/lotty19.png',
        '/images/lotties/lotty20.png',
        '/images/lotties/lotty21.png',
        '/images/lotties/lotty22.png',
        '/images/lotties/lotty23.png',
        '/images/lotties/lotty24.png',
        '/images/lotties/lotty25.png',
        '/images/lotties/lotty26.png',
        '/images/lotties/lotty27.png',
        '/images/lotties/lotty28.png',
        '/images/lotties/lotty29.png',
        '/images/lotties/lotty30.png',
        '/images/lotties/lotty31.png',
        '/images/lotties/lotty32.png',
        '/images/lotties/lotty33.png',
        '/images/lotties/lotty34.png',
        '/images/lotties/lotty35.png',
        '/images/roulette/lty-2000.svg',
        '/images/roulette/lty-1000-new.svg',
        '/images/roulette/pointer.svg',
        '/images/roulette/ticket-1.svg',
        '/images/roulette/ticket-2.svg',
        '/images/roulette/ton-0-01.svg',
        '/images/roulette/ton-0-001.svg',
        '/images/roulette/ton-0-005.svg',
        '/images/roulette/ton-3-new.svg',
        '/images/default-avatar.png',
        '/images/icons/add_to_home_screen.png',
        '/images/icons/promote_ton.png',
    ]);

    useEffect(() => {
        if (showWelcomeModal === true) {
            setAssets((prevAssets) => {
                const storiesAssets = [
                    '/images/stories/moon.svg',
                    '/images/stories/cashout.svg',
                    '/images/stories/collect.svg',
                    '/images/stories/flyer.svg',
                ]
                return [...prevAssets, ...storiesAssets]
            })
        }
    }, [showWelcomeModal]);

    useEffect(() => {
        if (user?.photoUrl) {
            setAssets((prevAssets) => {
                if (!prevAssets.includes(user.photoUrl)) {
                    return [...prevAssets, user.photoUrl];
                }
                return prevAssets;
            });
        }
    }, [user?.photoUrl]);

    useEffect(() => {
        if (isAssetsPreloaded) {
            setIsLoading(false);
            return;
        }

        let loadedAssets = 1;

        const loadAsset = (asset) =>
            new Promise((resolve) => {
                const img = new Image();
                img.src = asset;
                img.onload = () => {
                    loadedAssets += 1;
                    setLoadingProgress(Math.floor((loadedAssets / assets.length) * 100));
                    resolve();
                };
                img.onerror = resolve; // Обработка ошибки загрузки
            });

        Promise.all(assets.map(loadAsset)).then(() => {
            isAssetsPreloaded = true;
            setIsLoading(false);
        });
    }, [assets]);

    if (isLoading) {
        return <LoadingContainer loadingProgress={loadingProgress} />;
    }

    return <>{children}</>;
}

export default LoadingWrapper;