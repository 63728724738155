import React, { lazy, Suspense, useEffect } from "react";
import {SpinnerContent} from "../../Routes/authRoutes";

const LazyDotLottieReact = lazy(() =>
    import("@lottiefiles/dotlottie-react").then(module => ({ default: module.DotLottieReact }))
);

// Функция для предзагрузки
export const preloadDotLottieReact = () => {
    import("@lottiefiles/dotlottie-react");
};

const LazyDotLottie = (props) => {
    useEffect(() => {
        // Предзагрузка компонента при первом рендере
        preloadDotLottieReact();
    }, []);

    return (
        <Suspense fallback={<SpinnerContent />}>
            <LazyDotLottieReact
                {...props}
            />
        </Suspense>
    );
};

export default LazyDotLottie;