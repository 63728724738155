const IconTon = (props) => {
    return (
        // <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z" fill="#0098EA"/>
        //     <path d="M37.5603 15.6277H18.4386C14.9228 15.6277 12.6944 19.4202 14.4632 22.4861L26.2644 42.9409C27.0345 44.2765 28.9644 44.2765 29.7345 42.9409L41.5381 22.4861C43.3045 19.4251 41.0761 15.6277 37.5627 15.6277H37.5603ZM26.2548 36.8068L23.6847 31.8327L17.4833 20.7414C17.0742 20.0315 17.5795 19.1218 18.4362 19.1218H26.2524V36.8092L26.2548 36.8068ZM38.5108 20.739L32.3118 31.8351L29.7417 36.8068V19.1194H37.5579C38.4146 19.1194 38.9199 20.0291 38.5108 20.739Z" fill="white"/>
        // </svg>

        // <svg {...props} version="1.1" viewBox="0 0 512 512">
        //     <g>
        //         <circle fill="#499ece" cx="256" cy="256" r="249.6"/>
        //         <circle fill="#225c7c" cx="256" cy="256" r="228.6"/>
        //     </g>
        //     <path fill="#9dd8f9" d="M380.7,166.7c-6.9-11-19.5-17.8-33.1-17.7h-183.4c-13.6,0-26.1,6.7-33.1,17.7-6.9,11.1-6.9,24.8-.2,36l109.6,183.1c3.3,5.1,9.1,8.4,15.3,8.4s12-3.3,15.3-8.5l109.6-182.9c6.6-11.2,6.6-24.9-.2-36h0ZM242.6,317.8c0,5.3-7.4,7.1-10.1,2.5l-78.1-130.1v-.2c-2.1-3.2-2.1-7.2,0-10.4,1.9-3.5,5.8-5.5,9.9-5.4h72.9c3,0,5.4,2.3,5.4,5.1v138.5h0ZM357.6,190.1l-78.1,130.3c-2.8,4.5-10.1,2.7-10.1-2.5v-138.5c0-2.8,2.4-5.1,5.4-5.1h72.9c4.1,0,7.9,1.9,9.9,5.3,2.1,3.5,2.1,7.6,0,10.5h0Z"/>
        // </svg>


        <svg {...props} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_29)">
                <path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="url(#paint0_linear_13_29)"/>
                <path d="M343.845 150H169.018C136.873 150 116.499 184.674 132.671 212.705L240.568 399.721C247.609 411.932 265.254 411.932 272.295 399.721L380.213 212.705C396.363 184.719 375.989 150 343.867 150H343.845ZM240.48 343.637L216.982 298.16L160.284 196.754C156.543 190.263 161.163 181.946 168.996 181.946H240.458V343.659L240.48 343.637ZM352.535 196.732L295.858 298.182L272.36 343.637V181.924H343.823C351.655 181.924 356.275 190.241 352.535 196.732Z" fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_13_29" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0098EA"/>
                    <stop offset="1" stopColor="#005989"/>
                </linearGradient>
                <clipPath id="clip0_13_29">
                    <rect width="512" height="512" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default IconTon