import {Badge, Button} from "@telegram-apps/telegram-ui";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { getRaffleWinners } from "../../slices/raffles/thunk";
import { getMedal, getPrize } from "../../helpers/raffle_helper";
import { openTgUser } from "../../helpers/telegram_app_helper";
import {motion} from 'framer-motion'
const WinnerTable = (props) => {
    const dispatch = useDispatch();
    const { loading, winners, winnersMeta } = useSelector((state) => state.Raffles);
    const { selectedRaffle } = props;

    useEffect(() => {
        dispatch(getRaffleWinners(1, selectedRaffle));
    }, [dispatch, selectedRaffle]);

    const showMore = () => {
        dispatch(getRaffleWinners(winnersMeta.current_page + 1, selectedRaffle, true));
    };

    const prizes = selectedRaffle.matrix;
    const current_user_win = selectedRaffle?.current_user_win;

    return (
        <div className="fiat-winner-table winner-scroll d-flex w-100 p-2 flex-column">
            {/*{loading && <Spinner size={"s"} />} /!* Отображение лоадера *!/*/}

            <table className={"w-100"}>
                <tbody>
                        {winners &&
                            winners.map((el, i) => (
                                <tr>
                                    <td className={"d-flex gap-1"}>
                                        <span className={"d-flex"}>
                                            {getMedal(el.place, prizes, el)}
                                            {el.place}.
                                        </span>
                                        <span className={"d-flex align-items-center"}>
                                            <span className={"d-block text-truncate"}>
                                                {openTgUser(el?.user)}
                                            </span>
                                            {current_user_win && current_user_win.place === el.place && (
                                                <Badge type={"number"}>You</Badge>
                                            )}
                                        </span>
                                    </td>
                                    <td>{getPrize(el.prize.type, el.amount)}</td>
                                </tr>
                            ))}
                </tbody>
            </table>

            {winnersMeta?.last_page > winnersMeta?.current_page && (
                <div className={"pagination d-flex justify-content-center pt-2"}>
                    <Button loading={loading} onClick={showMore} mode={"plain"}>
                        Show more
                    </Button>
                </div>
            )}
        </div>
    );
};

WinnerTable.whyDidYouRender = true;

export default WinnerTable;