import React, { useState, useEffect } from 'react';

// Мемоизируем компонент Avatar, чтобы избежать лишних рендеров
const MemoizedAvatar = React.memo(({ src, fallbackSrc = '/images/default-avatar.png', size, rounded = true, maxRetries = 3, retryDelay = 1000 }) => {
    const [imageSrc, setImageSrc] = useState(src || fallbackSrc);
    const [retries, setRetries] = useState(0);

    const handleError = () => {
        if (retries < maxRetries) {
            setTimeout(() => {
                setRetries((prevRetries) => prevRetries + 1);
                setImageSrc(`${src}?retry=${Date.now()}`); // Добавляем параметр для обхода кеша
            }, retryDelay);
        } else {
            setImageSrc(fallbackSrc); // Если попытки исчерпаны, переключаемся на fallback
        }
    };

    useEffect(() => {
        setRetries(0); // Сбросить количество попыток при смене src
        setImageSrc(src || fallbackSrc);
    }, [src, fallbackSrc]);

    return (
        <img
            alt="avatar"
            style={{
                width: size,
                height: size,
                borderRadius: rounded ? '50%' : 0,
            }}
            src={imageSrc}
            onError={handleError}
        />
    );
});

export default MemoizedAvatar;