import {Button, FixedLayout, Headline, Title, Text, Subheadline, Caption, LargeTitle} from "@telegram-apps/telegram-ui";
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { motion } from 'framer-motion';
import alien from "../../../../../assets/lottie/dotlottie/alien.lottie";
import LazyDotLottie from "../../../LazyDotLottie";
import {emojiConverter} from "../../../../../helpers/format_helper";

const LottyDisqualified = (props) => {
    const { modalClose } = props;
    const [loading, setLoading] = useState(false);
    const { winnerData } = useSelector(state => state.Raffles);
    const [showFireworks, setShowFireworks] = useState(false);
    const [selectedRaffle, setSelectedLocalRaffle] = useState(false);
    const [shouldLoop, setShouldLoop] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldLoop(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);


    return (
        <>
            <div className={"d-flex gap-3 flex-column "} style={{ minHeight: "80vh", padding: '2rem'}}>
                <motion.div
                    className={"text-center"}
                    initial={{ y: -100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 25,
                        duration: 1,
                        delay: 3
                    }}
                >
                    <div className={"d-flex w-100 justify-content-center"}>
                        <LazyDotLottie
                            src={alien}
                            autoplay
                            loop={shouldLoop}
                            style={{
                                height: 100,
                                width: 100,
                            }}
                        />
                    </div>
                    <LargeTitle>Disqualification</LargeTitle>
                    <Title>Lotty #{winnerData?.raffle}</Title>

                </motion.div>

                <motion.div
                    className="fade-in"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, delay: 3.5, ease: "easeInOut" }}
                >
                    <div className={"d-flex flex-column gap-3 mb-3"}>
                        <Subheadline>
                            Our system has detected that you have not completed the required tasks, such as:
                        </Subheadline>
                        <div className={"d-flex flex-column w-100 gap-1"}>
                            {winnerData.errors && winnerData?.errors.map((el, i) => (
                                <Caption>{emojiConverter(i + 1)} {el}</Caption>
                            ))}
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <Text className={"subtitle-color"}>
                            You cannot participate in this Lotty unless all conditions are met at the time of the draw.
                        </Text>
                    </div>
                </motion.div>


            </div>

            <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 120,
                    damping: 20,
                    delay: 4
                }}
            >
                <FixedLayout
                    style={{
                        paddingBottom: "calc(var(--safe-bottom-padding))!important"
                    }}
                    className={"safe-padding-bottom main-bg super-z-index"}
                    vertical="bottom"
                >
                    <div className={"d-flex flex-column gap-2 w-100 text-center main-bg px-3 pt-3"}>

                        <Button
                            onClick={() => modalClose()}
                            mode={'gray'}
                            className={"w-100 grey-button"}
                        >
                            Ok
                        </Button>
                    </div>
                </FixedLayout>
            </motion.div>
        </>
    );
};

export default LottyDisqualified;