import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "@telegram-apps/telegram-ui";
import {authUser} from "../slices/auth/thunk";

const AuthProtected = (props) => {

    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.Auth)

    useEffect(() => {
        if (window.Telegram.WebApp && !user) {
            const { Telegram } = window;
            let data_string = Telegram.WebApp.initData
            let unsafe_data = Telegram.WebApp.initDataUnsafe
            dispatch(authUser(data_string, unsafe_data))
        }
    }, [dispatch, user]);

    if (user?.id) {
        return (
            <>{props.children}</>
        )
    }
    else
        return (
            <div className={"d-flex justify-content-center align-items-center vh-100 tg-bg"}>
                <Spinner size={"l"}></Spinner>
            </div>
        )
}

export default AuthProtected