import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { user } = useSelector(state => state.Auth);
    const [socket, setSocket] = useState(null); // Используем useState для сокета
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        const socketInstance = io(process.env.REACT_APP_SOCKET_SERVER, {
            transports: ['websocket'],
            secure: true,
            rejectUnauthorized: false,
        });

        socketInstance.on('connect', () => {
            setIsConnected(true);
            console.log(`Connected with socket ID: ${socketInstance.id}`);
            if (user) {
                socketInstance.emit('register', user.id);
                console.log(`User ${user.id} registered with socket ID: ${socketInstance.id}`);
            }
        });

        socketInstance.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
        });

        setSocket(socketInstance); // Устанавливаем сокет в состояние

        // Очистка при размонтировании
        return () => {
            socketInstance.off('connect');
            socketInstance.off('connect_error');
            socketInstance.disconnect();
        };
    }, [user]);

    return (
        <SocketContext.Provider value={{ socket, isConnected }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return context;
};