import React from "react";

const IconLottyStar = (props) => {
    return (
        // <svg {...props} version="1.1" viewBox="0 0 512 512">
        //     <g>
        //         <circle fill="#d2d2d2" cx="256" cy="256" r="249.6"/>
        //         <circle fill="#7a7a7a" cx="256" cy="256" r="199.7"/>
        //     </g>
        //     <path fill="#fff" d="M170.6,385.2c-14.7-3.2-10.9-19.1-6.9-35.1,7.2-26.9,7.6-31.9,17.1-44.7,13-12.4,29.1-19.7,44.2-26.8,14-6.9,23.3-10.5,35.2-16.6,8.7-4,15-8.9,7-13.8-9.1-2.1-21.6,2.2-30.9,3.2-12.4,2.3-40.5,7.8-50.9,9.3-22.2,3.7-35.6-3.2-52.6-17-10.7-8.7-22.4-19-21.9-20-5.5-7.9-.9-19.9,7.9-22.1,24-5.3,61.4-3.5,83.7-6.5,10-2.1,10.8-15,16.6-26.4,6.1-14.7,16.1-39.9,17.9-46,2.2-7.2,7.1-15,13.3-16.9,19.4-4.9,21.1,13.1,29.6,33,3.4,8.6,4.9,13.1,7.9,21,4.2,9.4,9.9,31.4,19.4,34.4,20.1,2.5,55.1,3.7,75.6,5.1,14.1-.9,26.5,8.7,17.1,21.3-2.4,3.1-16.4,16.4-22.4,21.1-4.6,4.1-26.3,21.8-30.2,25.3-8.3,6.9-15.3,11.6-12.9,23.3,3.7,27.2,11.8,46.7,17.4,73,2.6,22.3-15.2,26.9-35,11.2-12.9-8.6-20.3-12.7-32-20.1-8.7-5.1-20.9-14-27.8-13.6-21.6-.1-72.2,49.9-86.5,44.4h0Z"/>
        // </svg>

        // <svg {...props} version="1.1" viewBox="0 0 512 512">
        //     <circle fill="#d2d2d2" cx="256" cy="256" r="249.6"/>
        //     <circle fill="#727272" cx="256.5" cy="256.5" r="228.6"/>
        //     <path fill="#fff" d="M176.8,378.2c-13.6-2.9-10.2-17.8-6.4-32.8,6.7-25.1,7-29.8,16-41.8,12.1-11.6,27.2-18.5,41.2-25,13.1-6.4,21.8-9.7,32.8-15.4,8.1-3.7,14-8.4,6.5-12.9-8.5-2-20.2,2.1-28.8,3-11.6,2.2-37.7,7.2-47.6,8.7-20.8,3.5-33.3-2.9-49.1-15.9-10-8.1-20.9-17.8-20.4-18.6-5.1-7.4-.9-18.6,7.3-20.7,22.4-4.9,57.4-3.3,78.2-6.1,9.3-2,10.1-14,15.4-24.6,5.7-13.6,15.1-37.2,16.8-43,2.1-6.7,6.6-14,12.5-15.7,18.1-4.6,19.7,12.3,27.7,30.8,3.2,8,4.6,12.2,7.4,19.6,3.9,8.7,9.2,29.4,18.1,32.1,18.7,2.3,51.5,3.4,70.7,4.7,13.1-.8,24.8,8.1,16,19.9-2.2,2.9-15.3,15.3-20.9,19.6-4.3,3.8-24.5,20.4-28.2,23.6-7.7,6.4-14.3,10.9-12,21.8,3.4,25.4,11.1,43.6,16.2,68.2,2.5,20.8-14.2,25.1-32.7,10.4-12-8.1-18.9-11.9-29.9-18.7-8.1-4.7-19.4-13-26-12.7-20.2,0-67.4,46.6-80.7,41.5h0Z"/>
        // </svg>

        <svg {...props} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="url(#paint0_linear_13_34)"/>
            <path d="M264.484 265.76C264.484 267.905 263.606 269.414 261.85 270.447C243.247 281.173 223.127 291.66 201.49 301.908C182.808 310.807 171.79 325.425 168.597 345.764C167.239 354.264 166.201 362.606 165.483 370.71C163.487 392.717 172.828 398.993 193.347 389.618C210.752 381.753 228.317 373.014 246.201 363.401C252.189 360.144 259.375 360.144 265.363 363.401L322.129 393.909C330.832 398.517 341.69 395.259 346.32 386.6C347.997 383.421 348.716 379.767 348.317 376.192L339.614 303.1C339.135 299.446 340.412 295.791 343.047 293.09L399.254 237.159C402.288 234.14 403.566 229.85 402.767 225.718C401.011 216.979 395.263 212.054 385.602 210.782C362.289 207.763 339.853 204.983 318.377 202.44C312.229 201.725 306.8 197.991 304.085 192.43L269.195 123.946C265.522 116.796 256.82 113.936 249.634 117.591C246.92 118.941 244.684 121.166 243.247 123.867L206.76 193.781C203.886 199.263 198.457 202.838 192.389 203.314C171.87 205.062 151.511 207.684 131.311 211.021C107.439 214.993 103.607 225.957 119.894 243.912C124.285 248.838 129.315 253.604 134.904 258.212C149.914 270.686 167.16 275.532 186.56 272.831L260.892 262.582C262.648 262.344 264.245 263.536 264.484 265.363C264.484 265.522 264.484 265.68 264.484 265.76Z" fill="url(#paint1_linear_13_34)"/>
            <defs>
                <linearGradient id="paint0_linear_13_34" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CDCBCB"/>
                    <stop offset="1" stopColor="#747474"/>
                </linearGradient>
                <linearGradient id="paint1_linear_13_34" x1="256.5" y1="116" x2="256.5" y2="396" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#D1D1D1"/>
                </linearGradient>
            </defs>
        </svg>



        // <svg {...props} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z" fill="url(#paint0_linear_13_34)"/>
    //         <path d="M264.484 265.76C264.484 267.905 263.606 269.414 261.85 270.447C243.247 281.173 223.127 291.66 201.49 301.908C182.808 310.807 171.79 325.425 168.597 345.764C167.239 354.264 166.201 362.606 165.483 370.71C163.487 392.717 172.828 398.993 193.347 389.618C210.752 381.753 228.317 373.014 246.201 363.401C252.189 360.144 259.375 360.144 265.363 363.401L322.129 393.909C330.832 398.517 341.69 395.259 346.32 386.6C347.997 383.421 348.716 379.767 348.317 376.192L339.614 303.1C339.135 299.446 340.412 295.791 343.047 293.09L399.254 237.159C402.288 234.14 403.566 229.85 402.767 225.718C401.011 216.979 395.263 212.054 385.602 210.782C362.289 207.763 339.853 204.983 318.377 202.44C312.229 201.725 306.8 197.991 304.085 192.43L269.195 123.946C265.522 116.796 256.82 113.936 249.634 117.591C246.92 118.941 244.684 121.166 243.247 123.867L206.76 193.781C203.886 199.263 198.457 202.838 192.389 203.314C171.87 205.062 151.511 207.684 131.311 211.021C107.439 214.993 103.607 225.957 119.894 243.912C124.285 248.838 129.315 253.604 134.904 258.212C149.914 270.686 167.16 275.532 186.56 272.831L260.892 262.582C262.648 262.344 264.245 263.536 264.484 265.363C264.484 265.522 264.484 265.68 264.484 265.76Z" fill="url(#paint1_linear_13_34)"/>
    //         <defs>
    //             <linearGradient id="paint0_linear_13_34" x1="256" y1="0" x2="256" y2="512" gradientUnits="userSpaceOnUse">
    //                 <stop stopColor="#CDCBCB"/>
    //                 <stop offset="1" stopColor="#747474"/>
    //             </linearGradient>
    //             <linearGradient id="paint1_linear_13_34" x1="256.5" y1="116" x2="256.5" y2="396" gradientUnits="userSpaceOnUse">
    //                 <stop stopColor="white"/>
    //                 <stop offset="1" stopColor="#D1D1D1"/>
    //             </linearGradient>
    //         </defs>
    //     </svg>

    )
}

export default IconLottyStar