import React from 'react';
import { useInView } from 'react-intersection-observer';
import LazyDotLottie from "./LazyDotLottie";

const OptimizedLottie = (props) => {
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true
    });

    return (
        <div ref={ref}>
            {inView && (
                <LazyDotLottie
                    {...props}
                    autoplay={inView}
                    speed={0.6}
                />
            )}
        </div>
    );
};

export default React.memo(OptimizedLottie);