import React, { useState, useRef, useCallback } from 'react';
import { Tooltip, Subheadline } from "@telegram-apps/telegram-ui";
import MemoizedAvatar from "./MemoizedAvatar";
import {useSelector} from "react-redux";

const TooltipProfile = ({ user }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const centerButtonRef = useRef(null);
    const {userStatInfo} = useSelector(state => state.Users)

    // Мемоизируем обработчик клика
    const handleClick = useCallback(() => {
        setShowTooltip((prev) => !prev);
    }, []);

    return (
        <div>
            <div ref={centerButtonRef} onClick={handleClick}>
                <MemoizedAvatar
                    src={
                        user?.photoUrl
                            ? user.photoUrl
                            : user?.username
                                ? `https://unavatar.io/telegram/${user.username}`
                                : '/images/default-avatar.png'
                    }
                    size={48}
                />
            </div>

            {showTooltip && (
                <Tooltip
                    className="tooltip-profile"
                    mode="dark"
                    placement="top"
                    targetRef={centerButtonRef}
                >
                    <div className="user-info" style={{
                        minWidth: '180px'
                    }}>
                        <div className="d-flex gap-2 align-items-center">
                            <div className="d-flex flex-column w-100">
                                <div className={"d-flex flex-column mb-2"}>
                                    <Subheadline>
                                        {user?.firstName} {user?.lastName}
                                    </Subheadline>
                                    {user.username && <span className="subtitle-color">@{user?.username}</span>}
                                </div>
                                <div className={"d-flex flex-column w-100"}>
                                    <div className={"d-flex justify-content-between"}>
                                        <span>Playing now:</span>
                                        <span className="subtitle-color  fw-bold">{userStatInfo?.stat?.participating}</span>
                                    </div>
                                    <div className={"d-flex justify-content-between"}>
                                        <span>Total wins:</span>
                                        <span className="subtitle-color fw-bold">{userStatInfo?.stat?.wins}</span>
                                    </div>
                                    <div className={"d-flex justify-content-between"}>
                                        <span>Top wins:</span>
                                        <span className="subtitle-color  fw-bold">{userStatInfo?.stat?.prize_places}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default TooltipProfile;