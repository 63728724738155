import {Progress} from "@telegram-apps/telegram-ui";

const LoadingContainer = (props) => {
    const {loadingProgress} = props

    return (
        <div className={"d-flex min-vh-100 justify-content-center align-items-center main-bg"}>
            <div className={"loading-progress w-50"}>
                <Progress value={loadingProgress} />
            </div>

        </div>
    )
}

export default LoadingContainer