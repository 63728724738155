import {createSlice} from "@reduxjs/toolkit";

export const initialState = {
    friends: [],
    meta: null,
    loading: false,
    showSkeleton: false,
    loaded: false,
    transactions: null,
    ticketMeta: null
}

const appSlice = createSlice({
    name: "friends",
    initialState,
    reducers: {
       setFriends(state, action) {
           if (action.payload.append) {
               state.friends = [...state.friends, ...action.payload.data];
           } else {
               state.friends = action.payload.data;
           }
           state.loaded = true
       },
        setTicketTransactions(state, action) {
            if (action.payload.append) {
                state.transactions = [...state.transactions, ...action.payload.data];
            } else {
                state.transactions = action.payload.data;
            }
            state.loaded = true
        },
        setTicketMeta(state, action) {
            state.ticketMeta = action.payload
        },
       setFriendMeta(state, action) {
           state.meta = action.payload
       },
        setLoading(state, action) {
           state.loading = action.payload
        },
        setShowSkeleton(state, action) {
            state.showSkeleton = action.payload
        }
    }
})

export const {
    setFriends,
    setFriendMeta,
    setLoading,
    setShowSkeleton,
    setTicketTransactions,
    setTicketMeta
} = appSlice.actions

export default appSlice.reducer