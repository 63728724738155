import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { Button, Headline, Subheadline } from "@telegram-apps/telegram-ui";
import React, { useEffect, useState } from "react";
import { setShowErrorModal, setErrorTitle } from "../../slices/app/reducer";
import { emojiConverter } from "../../helpers/format_helper";

const ErrorModal = () => {
    const { showErrorModal, errorTitle } = useSelector((state) => state.App);
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);

    // Устанавливаем стандартное значение заголовка, если errorTitle отсутствует
    const title = errorTitle || "Attention!";

    useEffect(() => {
        if (showErrorModal) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [showErrorModal]);

    const handleClose = () => {
        setIsVisible(false); // Устанавливаем анимацию скрытия
        setTimeout(() => {
            dispatch(setShowErrorModal(null)); // Закрываем модалку
            dispatch(setErrorTitle(null)); // Сбрасываем заголовок
        }, 300); // Убираем модалку после окончания анимации
    };

    if (!showErrorModal && !isVisible) return null;

    const appRoot = document.getElementById("app-root");
    if (!appRoot) {
        console.error("AppRoot элемент не найден в DOM");
        return null;
    }

    const renderContent = () => {
        if (Array.isArray(showErrorModal)) {
            return showErrorModal.map((item, index) => (
                <Subheadline key={index} className={"text-break"}>
                    {emojiConverter(index + 1)} {item}
                </Subheadline>
            ));
        } else {
            return (
                <div dangerouslySetInnerHTML={{ __html: showErrorModal }}></div>
            );
        }
    };

    const content = (
        <div className={`custom-overlay ${isVisible ? "show" : "hide"}`}>
            <div className={`custom-modal ${isVisible ? "fade-in" : "fade-out"}`}>
                <div className={"d-flex b-8 justify-content-center flex-column p-3 w-75 modal-block main-bg"}>
                    <div className={"d-flex w-100 flex-column gap-3"}>
                        <div className={"custom-modal-body gap-2 flex-column d-flex text-center"}>
                            <Headline weight={2}>{title}</Headline>
                            {renderContent()}
                        </div>
                        <div className={"custom-modal-footer"}>
                            <Button onClick={handleClose} stretched mode={"bezeled"}>
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(content, appRoot);
};

export default ErrorModal;