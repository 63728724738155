import React, { lazy, Suspense } from "react";

const TonConnectUIProviderLazy = lazy(() =>
    import("@tonconnect/ui-react").then((module) => ({
        default: ({ children, manifestUrl }) => (
            <module.TonConnectUIProvider manifestUrl={manifestUrl}>
                {children}
            </module.TonConnectUIProvider>
        ),
    }))
);

const LazyTonConnectProvider = ({ children, manifestUrl }) => (
    <Suspense fallback={''}>
        <TonConnectUIProviderLazy manifestUrl={manifestUrl}>
            {children}
        </TonConnectUIProviderLazy>
    </Suspense>
);

export default LazyTonConnectProvider;