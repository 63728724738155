import React, { useState, useEffect } from "react";
import { RandomReveal } from "react-random-reveal";

const RandomNumberReveal = ({ targetNumber, maxNumber }) => {
    const [isFinal, setIsFinal] = useState(false); // Отображать ли финальное число
    const [randomString, setRandomString] = useState(""); // Текущая строка

    useEffect(() => {
        let isMounted = true; // Флаг для проверки, размонтирован ли компонент

        const generateRandomNumber = () => {
            // Генерируем случайное число от 1 до maxNumber
            const randomNumber = Math.floor(Math.random() * maxNumber) + 1;
            return randomNumber.toString();
        };

        const interval = setInterval(() => {
            if (isMounted) {
                setRandomString(generateRandomNumber());
            }
        }, 200); // Частота обновления рандомных чисел

        const timeout = setTimeout(() => {
            if (isMounted) {
                clearInterval(interval);
                setIsFinal(true);
            }
        }, 2000); // Останавливаем через 2 секунды

        return () => {
            isMounted = false; // Установить флаг в `false` при размонтировании
            clearInterval(interval); // Очистить интервал
            clearTimeout(timeout); // Очистить таймаут
            setIsFinal(true); // Установить финальное число
        };
    }, [maxNumber]);

    return (
        <RandomReveal
            isPlaying
            duration={3} // Длительность обновления каждого символа
            revealDuration={0.05} // Скорость смены каждого символа
            characters={isFinal ? String(targetNumber) : randomString} // Показать целевое число или случайную строку
            characterSet={"123456789"} // Исключаем ноль как возможный символ
        />
    );
};

export default RandomNumberReveal;