import { Headline, LargeTitle } from "@telegram-apps/telegram-ui";
import {motion} from 'framer-motion'
const FirstStory = () => {
    return (
        <div className={"story-container h-100"}>
            <div className={"d-flex flex-column w-100 p-4 text-uppercase"}>
                
                    <motion.div
                        className={"pb-3"}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <Headline className={"fw-200 text-grey"}>
                            Play
                        </Headline>
                    </motion.div>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                    >
                        <LargeTitle className={"text-purple"} weight={2}>Play lotty</LargeTitle>
                    </motion.div>

                    <motion.div
                        initial={{ x: 100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ type: "spring", stiffness: 50, duration: 0.5, delay: 1 }}
                    >
                        <LargeTitle weight={2} className={"text-grey"}>and win real prizes</LargeTitle>
                    </motion.div>
                
            </div>
        </div>
    );
};

export default FirstStory;