import { Button, FixedLayout, Headline, LargeTitle, Title } from "@telegram-apps/telegram-ui";
import ordinal, { getPrize } from "../../../../../helpers/raffle_helper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {setFinalModalData, setReward, setShowErrorModal} from "../../../../../slices/app/reducer";
import { showConfetti } from "../../../../../slices/app/thunk";
import { getRaffleApi } from "../../../../../helpers/real_api_helper";
import { setSelectedRaffle } from "../../../../../slices/raffles/reducer";
import RandomNumberReveal from "../../../RandomNumberReveal";
import { GiTrophyCup } from "react-icons/gi";
import TicketNumber from "../../Parts/TicketNumber";
import { motion } from 'framer-motion';
import TunedCard from "../../Parts/TunedCard";

const LottyWinner = (props) => {
    const { modalClose, FireworksParticlesComponent } = props;
    const [loading, setLoading] = useState(false);
    const { winnerData } = useSelector(state => state.Raffles);
    const [showFireworks, setShowFireworks] = useState(false);
    const dispatch = useDispatch();
    const [selectedRaffle, setSelectedLocalRaffle] = useState(false);

    useEffect(() => {
        if (winnerData) {
            const data = {
                ticket_number: winnerData.ticket_number,
                style_number: winnerData.style_number
            };
            setSelectedLocalRaffle(data);
        }
    }, [winnerData]);

    const getWinnerPrize = () => {
        let reward = [{
            reward_type: winnerData?.prize?.type,
            amount: winnerData?.prize?.amount,
            transaction_type: 'credit'
        }];
        dispatch(setReward(reward));
        dispatch(showConfetti());
        dispatch(setFinalModalData(false))
        modalClose();
    };

    const showAllWinners = () => {
        setLoading(true);
        getRaffleApi(winnerData?.raffle)
            .then(res => {
                dispatch(setSelectedRaffle(res.data));
            })
            .catch(err => {
                dispatch(setShowErrorModal(err.message))
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (FireworksParticlesComponent) {
            setShowFireworks(true); // Отображаем фейерверки, когда компонент доступен
        }
    }, [FireworksParticlesComponent]);

    return (
        <>
            {/* Загружаем FireworksParticles только когда он готов */}
            {showFireworks && FireworksParticlesComponent && (
                <FireworksParticlesComponent />
            )}

            <div className={"d-flex gap-3 justify-content-center flex-column"} style={{ minHeight: "80vh", paddingBottom: 125 }}>
                <motion.div
                    initial={{ y: -100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 100,
                        damping: 25,
                        duration: 1,
                        delay: 3.5
                    }}
                >
                    <LargeTitle weight={1}>
                        <div className={"d-flex justify-content-center align-items-center winner-prize"}>
                            {getPrize(winnerData?.prize?.type, winnerData?.prize?.amount, true, false)}
                        </div>
                    </LargeTitle>
                </motion.div>

                <motion.div
                    className="fade-in"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                    <div className={"d-flex gap-2 flex-column text-center pb-3"}>
                        <LargeTitle style={{ fontFamily: "Chakra Petch" }}>Congratulations!</LargeTitle>
                        <Title style={{ fontFamily: "Chakra Petch" }}>
                            You are the winner of Lotty #{winnerData?.raffle}
                        </Title>
                    </div>
                    <div className={"d-flex gap-3 justify-content-center px-4"}>
                        <div className={"w-50"}>
                            <TicketNumber selectedRaffle={selectedRaffle} />
                        </div>
                        <div className={"w-50"}>
                            <TunedCard>
                                <LargeTitle className={"text-white"} weight={1}>
                                    <RandomNumberReveal maxNumber={winnerData?.max_participants} targetNumber={winnerData?.place} />
                                </LargeTitle>
                            </TunedCard>
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    onAnimationComplete={() => {
                        if (FireworksParticlesComponent) {
                            setShowFireworks(true);
                        }
                    }}
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 120,
                        damping: 20,
                        delay: 3
                    }}
                >
                    <div className={"d-block text-center"}>
                        <Headline className={"subtitle-color"}>
                            You ranked {ordinal(winnerData?.place)} out of {winnerData?.max_participants}
                        </Headline>
                    </div>
                </motion.div>
            </div>

            <motion.div
                onAnimationComplete={() => {
                    if (FireworksParticlesComponent) {
                        setShowFireworks(true);
                    }
                }}
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 120,
                    damping: 20,
                    delay: 4.5
                }}
            >
                <FixedLayout
                    style={{
                        paddingBottom: "calc(var(--safe-bottom-padding))!important"
                    }}
                    className={"safe-padding-bottom main-bg super-z-index"}
                    vertical="bottom"
                >
                    <div className={"d-flex flex-column gap-2 w-100 text-center main-bg px-3 pt-3"}>
                        <Button
                            onClick={() => showAllWinners()}
                            mode={'gray'}
                            loading={loading}
                            style={{
                                color: '#fdb345'
                            }}
                            before={<GiTrophyCup />}
                            className={"w-100 grey-button"}
                        >
                            Show all winners
                        </Button>
                        <Button
                            onClick={() => getWinnerPrize()}
                            mode={'gray'}
                            className={"w-100 grey-button"}
                        >
                            Close
                        </Button>
                    </div>
                </FixedLayout>
            </motion.div>
        </>
    );
};

export default LottyWinner;