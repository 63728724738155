import {useDispatch} from "react-redux";
import {setWelcomeModal} from "../../../slices/app/reducer";
import {TfiClose} from "react-icons/tfi";
import {updateUserApi} from "../../../helpers/real_api_helper";

const StoryHeader = () => {
    const dispatch = useDispatch()
    const closeStories = () => {
        let data = {
            show_welcome_stories: false
        }
        updateUserApi(data)
        dispatch(setWelcomeModal(false))
    }

    return (
        <div className={"d-flex justify-content-end pe-3 pt-2"}>
            <TfiClose className={"text-grey"} onClick={closeStories} size={22} />
        </div>
    )
}
export default StoryHeader