import Countdown from "react-countdown";
import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setShowFinalWinning, setShowPrepareWinning } from "../../../../../slices/app/reducer";
import {motion} from 'framer-motion'

const LottyCountdown = React.memo(() => {
    const dispatch = useDispatch();

    // Устанавливаем время отсчета как текущее время + 3 секунды
    const date = useMemo(() => Date.now() + 3000, []); // Время вычисляется один раз

    // Завершающий коллбек
    const onComplete = useCallback(() => {
        dispatch(setShowPrepareWinning(false));
        dispatch(setShowFinalWinning(true));
    }, [dispatch]);

    // Рендерер
    const renderer = useCallback(
        ({ seconds }) => (
            <div className="d-flex gap-1 lotty-win-countdown">
                <div className="p-2">
                    
                        <motion.div
                            initial={{ scale: 1 }}
                            animate={{ scale: [1, 1.2, 1] }} // Увеличиваем и уменьшаем
                            transition={{
                                duration: 1,
                                ease: "easeInOut",
                                repeat: Infinity, // повторяется бесконечно
                            }}
                        >
                            {seconds}
                        </motion.div>
                    
                </div>
            </div>
        ),
        [] // Зависящие пропсы отсутствуют
    );

    return (
        <Countdown
            zeroPadTime={2}
            date={date}
            renderer={renderer}
            onComplete={onComplete}
        />
    );
});

export default LottyCountdown;