import {
    getCurrentUserApi, getCurrentUserLottyInfoApi, getCurrentUserRefsInfoApi,
    getCurrentUserWinInfoApi,
    getNotificationsApi,
    getTopLottyUsersApi, getTopRefsApi,
    getTopUsersApi,
    getTopWinnersApi
} from "../../helpers/real_api_helper";
import {setCurrentUser, setTopUsers, setTopWinners, setUserWinInfo, setWinnersLoading} from "./reducer";
import {setWinnerQueue} from "../raffles/reducer";
export const getTopWinners = (type = 'ton') => async (dispatch) => {
    try {
        dispatch(setWinnersLoading(true))
        let res;
        if (type === 'ton')
            res = await getTopWinnersApi();
        else if (type === 'lotty')
            res = await getTopLottyUsersApi();
        else if (type === 'referrals')
            res = await getTopRefsApi();

        dispatch(setTopWinners(res.data));
        dispatch(setCurrentUser(res.currentUser));
    } catch (error) {
        console.error(error);
    }
    finally {
        dispatch(setWinnersLoading(false))
    }
}

export const getTopUsers = () => async (dispatch) => {
    try {
        const res = await getTopUsersApi();
        dispatch(setTopUsers(res.data));
    } catch (error) {
        console.error(error);
    }
}

export const getNotifications = () => async (dispatch) => {
    getNotificationsApi()
        .then(res => {
            if (res.data) {
                let data = res.data.map(el => {
                    return {
                        max_participants: el.payload.max_participants,
                        place: el.payload.place,
                        prize: el.payload.prize,
                        raffle: el.payload.raffle,
                        ticket_number: el.payload.ticket_number,
                        user: el.payload.user,
                        notification: el.id,
                        style_number: el.payload.style_number,
                        type: el.payload.type,
                        errors: el.payload?.errors
                    }
                })
                if (data)
                    dispatch(setWinnerQueue(data))
                // Диспатчим уведомления в очередь

            }
        })
        .catch(err => {

        })
}

export const getCurrentUser = () => async (dispatch) => {
    getCurrentUserApi()
        .then(res => {
            dispatch(setCurrentUser(res.data))
        })
        .catch(err => {

        })
}

export const getCurrentUserWinInfo = (type = 'ton') => async (dispatch) => {
    try{
        let res;
        if (type === 'ton')
            res = await getCurrentUserWinInfoApi();
        else if (type === 'lotty')
            res = await getCurrentUserLottyInfoApi();
        else if (type === 'referrals')
            res = await getCurrentUserRefsInfoApi();

        dispatch(setUserWinInfo(res.data))
    }
    catch (e) {

    }
    finally {

    }
}