import IconTon from "../../../icons/IconTon";
import {formatNumber} from "../../../../helpers/format_helper";
import IconLottyStar from "../../../icons/IconLottyStar";
import React from "react";
import IconLottyCoin from "../../../icons/IconLottyCoin";
import {Caption, Skeleton} from "@telegram-apps/telegram-ui";

const Prizes = ({prizes, skeleton}) => {
    return (
        <>
            {prizes && prizes.map((el, i) => {
                switch (el.name) {
                    case "TON":
                        return (
                            <Skeleton key={i} visible={skeleton}>
                                <div
                                    className={"d-flex gap-1 align-items-center flex-grow-1"}>
                                    <IconTon style={{
                                        height: 12
                                    }}/>
                                    <Caption
                                        className={"ton_num"}
                                    >{formatNumber(el.amount)}</Caption>
                                </div>
                            </Skeleton>
                        )
                    case "Stars" :
                        return (
                            <Skeleton key={i} visible={skeleton}>
                            <div key={i} className={"d-flex gap-1 align-items-center flex-grow-1 "}>
                                <IconLottyStar
                                    style={{
                                        height: 12
                                    }}
                                />
                                <Caption
                                    className={"ton_num"}
                                >{formatNumber(el.amount)}</Caption>
                            </div>
                            </Skeleton>
                        )
                    case "Coins" :
                        return (
                            <Skeleton key={i} visible={skeleton}>
                            <div key={i} className={"d-flex gap-1 align-items-center flex-grow-1"}>
                                <IconLottyCoin
                                    style={{
                                        height: 12
                                    }}
                                />
                                <Caption
                                    className={"ton_num"}
                                >{formatNumber(el.amount)}</Caption>
                            </div>
                            </Skeleton>
                        )
                    default:
                        return ('')
                }
            })}
        </>
    )
}

export default Prizes