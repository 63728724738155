import {useDispatch, useSelector} from "react-redux";
import bell from "../assets/lottie/dotlottie/bell.lottie";
import ticket from "../assets/lottie/dotlottie/ticket.lottie";
import {
    addToHomeScreen,
    getInviteLink,
    inviteFriends,
    openLink,
    openTelegramLink, sendTonTransaction,
    shareStory,
} from "../helpers/telegram_app_helper";
import {Button, Caption} from "@telegram-apps/telegram-ui";
import link from "../assets/lottie/dotlottie/link.lottie";
import React from "react";
import {Link} from "react-router-dom";
import LazyDotLottie from "../Components/custom/LazyDotLottie";
import IconLottyTicket from "../Components/icons/IconLottyTicket";

export const useTaskRow = () => {
    const {user} = useSelector(state => state.Auth)
    const {balance} = useSelector(state => state.Balance)
    const dispatch = useDispatch()

    const getEmoji = (type) => {
        switch (type) {
            case 'telegram_subscription':
                return (
                    <LazyDotLottie
                        src={bell}
                        loop
                        autoplay
                        style={{
                            flex: "0 0 auto",
                            height: 20,
                            width: 20,
                        }}
                    />
                )
            case 'invite_referral':
                return (
                    <LazyDotLottie
                        src={link}
                        loop
                        autoplay
                        style={{
                            flex: "0 0 auto",
                            height: 20,
                            width: 20,
                        }}
                    />
                )
            case 'farm_ticket':
                return <IconLottyTicket style={{
                    width: 20,
                    height: 20
                }} />
            default:
                return (
                    <LazyDotLottie
                        src={bell}
                        loop
                        autoplay
                        style={{
                            flex: "0 0 auto",
                            height: 20,
                            width: 20,
                        }}
                    />
                )
        }
    }

    const getTaskLink = (task, parent_task) => {
        switch(task.type) {
            case 'telegram_subscription':
                let path = task.details.link
                return <a onClick={() => openTelegramLink(`${path}`, parent_task) }>{path}</a>
            case 'youtube_subscription':
            case 'instagram_subscription':
            case 'x_subscription':
            case 'tiktok_subscription':
            case 'facebook_subscription':
                let link = task.details.link
                return <a onClick={() => openLink(link, parent_task)}>{link}</a>
            case 'invite_referral':
                let count = task.details.count
                if (count > 0) {
                    return (
                        <span>Invite {count} {count > 1 ? 'friends' : 'friend'}</span>
                    )
                }
                else
                    return ''
            case 'farm_ticket':
                let count_tickets = task.details.count
                if (count_tickets > 0) {
                    return (
                        <span>You have <b className={balance?.ticket_balance >= count_tickets ? 'text-green': 'text-red'}>{balance?.ticket_balance}</b> of <b>{count_tickets}</b> required {count_tickets > 0 ? 'tickets' : 'ticket'}</span>
                    )
                }
                else
                    return ''
            default:
                return ''
        }
    }

    const getTaskText = (task) => {
        switch(task.type) {
            case 'telegram_subscription':
            case 'youtube_subscription':
            case 'instagram_subscription':
            case 'x_subscription':
            case 'tiktok_subscription':
            case 'facebook_subscription':
                return 'Subscribe to'
            case 'lotty_participate':
                return 'Participate at least in 1 Lotty'
            case 'channel_boost':
                return 'Boost Telegram channel'
            case 'story_share':
                return 'Share in Telegram Stories'
            case 'silver_stars_spend':
                return 'Spend 1K silver stars anywhere in the Lotty app'
            case 'telegram_stars_spend':
                return 'Spend 20 telegram stars anywhere in the Lotty app'
            case 'wheel_spin':
                return 'Spin Lotty wheel at least 1 time'
            case 'claim_lotty':
                return 'Claim 1K Lotty coins in tasks, raffles, or games anywhere in Lotty app'
            case 'add_to_home_screen':
                return 'Add the Lotty mini-app to your phone\'s home screen for quick access'
            case 'promote_ton':
                return 'Send transaction in TON network'
            default:
                return ''
        }
    }

    const getTaskButton = (task, parent_task) => {
        let button;

        switch(task.type) {
            case 'telegram_subscription':
                button = (
                    <Button
                        onClick={() => openTelegramLink(task.details.link, parent_task)}
                        stretched
                    >
                        Subscribe
                    </Button>
                );
                break;

            case 'youtube_subscription':
            case 'instagram_subscription':
            case 'x_subscription':
            case 'tiktok_subscription':
            case 'facebook_subscription':
                button = (
                    <Button
                        onClick={() => openLink(task.details.link, parent_task)}
                        stretched
                    >
                        Subscribe
                    </Button>
                );
                break;

            case 'invite_referral':
                if (task.details.count > 0) {
                    button = (
                        <Button
                            onClick={() => inviteFriends(user)}
                            stretched
                        >
                            Invite
                        </Button>
                    );
                }
                break;
            case 'farm_ticket':
                if (task.details.count > balance?.ticket_balance) {
                    button = (
                        <Button
                            onClick={() => inviteFriends(user)}
                            stretched
                        >
                            Farm Tickets
                        </Button>
                    );
                }
                break;

            case 'lotty_participate':
                button = (
                    <Link className="link-color" to="/lotty">
                        <Button stretched>
                            Participate
                        </Button>
                    </Link>
                );
                break;

            case 'channel_boost':
                button = (
                    <Button
                        onClick={() => openTelegramLink(task.details.link)}
                        stretched
                    >
                        Boost channel
                    </Button>
                );
                break;

            case 'telegram_stars_spend':
                button = (
                    <div className={"d-flex flex-column text-center gap-1"}>
                        <Link className="link-color" to="/lotty">
                            <Button
                                stretched
                            >
                                Participate in a Lotty
                            </Button>
                        </Link>
                        <Caption className={"subtitle-color"} weight={2} level={2}>OR</Caption>
                        <Link className="link-color" to="/roulette">
                            <Button
                                stretched
                            >
                                Spin Lotty Wheel
                            </Button>
                        </Link>
                    </div>

                );
                break;
            case 'silver_stars_spend':
                button = (
                    <div className={"d-flex flex-column text-center gap-1"}>
                        <Link className="link-color" to="/roulette">
                            <Button
                                stretched
                            >
                                Spin Lotty Wheel
                            </Button>
                        </Link>
                    </div>

                );
                break;

            case 'story_share':
                let link = getInviteLink(user)
                const params = {
                    text: link,
                    ...(user.isPremium && {
                        widget_link: {
                            url: link,
                            name: "@Lotty"
                        }
                    })
                };
                button = (
                    <Button
                        onClick={() => shareStory(task.details.media_link, params)}
                        stretched
                    >
                        Share Story
                    </Button>
                );
                break;

            case 'wheel_spin':
                button = (
                    <Link className="link-color" to="/roulette">
                        <Button
                            stretched
                        >
                            Spin the wheel
                        </Button>
                    </Link>
                );
                break;

            case 'add_to_home_screen':
                button = (
                    <Button
                        onClick={() => addToHomeScreen(dispatch, parent_task)}
                        stretched
                    >
                        Add to Home Screen
                    </Button>
                );
                break;

            case 'promote_ton':
                button = (
                    <Button
                        onClick={() => sendTonTransaction(user)}
                        stretched
                    >
                        Promote TON
                    </Button>
                );
                break;

            default:
                button = null;
        }

        return (
            button && <div className="w-100">
                {button}
            </div>
        );
    }


    const getTaskRow = (task, i, parent_task = false) => {

        return (
            <div key={i} className={"requirement flex-column gap-3 d-flex"}>
                <div className={"name d-flex gap-2 flex-wrap align-items-center w-100"}>
                    <div className={"icon"}>
                        {getEmoji(task.type)}
                    </div>
                    <span className="d-inline-block">
                        {getTaskText(task)}
                    </span>
                    <div className="link-container">
                        {getTaskLink(task, parent_task)}
                    </div>
                </div>
                {getTaskButton(task, parent_task)}
            </div>
        )
    }

    return {getTaskRow}
}